export const environment = {
    production: false,
    applicationUuid: '384f86b6-3b96-11ea-b77f-2e728ce88125',
    playersCdnPath: 'internal-test-splitscreen-adopt.applearn.tv',
    appGatewayEndpoint: 'internal-test-app-gateway.applearn.tv',
    eventUrl: 'internal-test-events-adopt.applearn.tv',
    eventStream: 'internal-test-training-pages',
    pdfViewerUrl: 'https://internal-test-cdn.applearn.tv/test-pdf-viewer/web/viewer.html',
    brightcove: {
        accountId: '1225372802001',
        playerId: 'HJgzzua9b'
    },
    adoptSnippetUrl: 'https://internal-test-splitscreen-adopt.applearn.tv/dist/applearnapc.js',
    cloudEdgeProxyUrl: 'https://int-cloud.applearn.tv',
};
