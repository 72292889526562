import { Event } from './types/Event';
import { EventContext } from './types/EventContext';
import { PageContext } from './types/PageContext';
import { UserContext } from './types/UserContext';
import { BrowserContext } from './types/BrowserContext';
import { EventDispatchable } from './dispatch/EventDispatchable';

export abstract class EventClient {
    protected abstract eventType: string;

    protected constructor(private eventDispatchable: EventDispatchable) {}

    public dispatch(event: Event): void {
        this.eventDispatchable.dispatch(event);
    }

    public buildEvent(): Event {
        return {
            detail: this.eventDetail(),
            event_context: this.eventContext(),
            page_context: this.pageContext(),
            user_context: this.userContext(),
            browser_context: this.browserContext()
        };
    }

    public abstract eventContext(): EventContext;
    public abstract pageContext(): PageContext;
    public abstract userContext(): UserContext;
    public abstract browserContext(): BrowserContext;
    public abstract eventDetail(): any;
}
